import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { stripUnit } from 'polished'
import { object } from 'prop-types'

import * as spacing from 'styles/spacing'
import Layout from 'components/Layout'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'

import { H1 } from 'styles/typography'

const SpacedH1 = styled(H1)`
  margin-bottom: ${stripUnit(spacing.xLarge) * 2}px;
`

const StaticPage = ({ pageContext }) => (
  <Layout>
    <Helmet title={pageContext.title} />
    <Section>
      <Wrapper>
        <SpacedH1>{pageContext.title}</SpacedH1>
        <SectionBlock>
          <div dangerouslySetInnerHTML={{ __html: pageContext.body }} />
        </SectionBlock>
      </Wrapper>
    </Section>
  </Layout>
)

StaticPage.propTypes = {
  pageContext: object
}

export default StaticPage
